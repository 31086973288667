$(document).ready(function() {
    // Search form on header
    $(document).on('change', '.search-form select', function() {
        var value = $(this).val(),
            form = $(this).parents('.search-form'),
            input = form.find('input.keywords'),
            placeholder = 'PRODUCTS';

        if (value == "blog") {
            placeholder = 'ARTICLES';
        }

        $('.aa-dropdown-menu').remove();
        input.unwrap();
        input.attr('placeholder', 'SEARCH ' + placeholder + '...');
        form.attr('action', '/' + value);
        /*eslint-disable */
        init_algolia_search (value);
        /*eslint-enable */
    });
})